@use "sass:string";
@use "../../config" as *;
@use "../../core/functions" as *;
@use "../../core/mixins" as *;

// Flex clases
@mixin create-flex {
  .flex {
    @include flexbox;

    &.inline {
      display: inline-flex;
      width: auto;
    }
  }

  .direction {
    @include set-directions;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-directions($bp);
      }
    }
  }

  .wrap {
    @include set-wrap;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-wrap($bp);
      }
    }
  }

  .center {
    @include centered;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include centered($bp);
      }
    }
  }

  // Generar clases de alineación (items, content, justify)
  .items {
    @each $name, $value in $flex-values {
      @include align("items", $name);
      @each $bp, $value in $breakpoints {
        @include since($bp) {
          @include align("items", $name, $bp);
        }
      }
    }
  }
  .content {
    @each $name, $value in $flex-values {
      @include align("content", $name);
      @each $bp, $value in $breakpoints {
        @include since($bp) {
          @include align("content", $name, $bp);
        }
      }
    }
  }
  .justify {
    @each $name, $value in $flex-values {
      @include align("justify", $name);
      @each $bp, $value in $breakpoints {
        @include since($bp) {
          @include align("justify", $name, $bp);
        }
      }
    }
  }
  .self {
    @each $name, $value in $flex-values {
      @include align("self", $name);
      @each $bp, $value in $breakpoints {
        @include since($bp) {
          @include align("self", $name, $bp);
        }
      }
    }
  }

  // Generar clases de grid
  .w {
    @include set-grid;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-grid($bp);
      }
    }
  }

  .maxw {
    @include set-max-grid;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-max-grid($bp);
      }
    }
  }

  .flex {
    @include set-flex;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-flex($bp);
      }
    }
  }

  .grow {
    @include set-flex-grow;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-flex-grow($bp);
      }
    }
  }

  .shrink {
    @include set-flex-shrink;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-flex-shrink($bp);
      }
    }
  }

  .basis {
    @include set-flex-basis;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-flex-basis($bp);
      }
    }
  }

  .order {
    @include set-order;
    @each $bp, $value in $breakpoints {
      @include since($bp) {
        @include set-order($bp);
      }
    }
  }
}
