@use "sass:map";
@use "sass:string";
@use "../../config" as *;
@use "../functions" as *;

$flex-alignments: (
  items: align-items,
  content: align-content,
  justify: justify-content,
  self: align-self,
);

$flex-values: (
  center: center,
  start: flex-start,
  end: flex-end,
  around: space-around,
  between: space-between,
  even: space-evenly,
);

// Mixin para flexbox (reduce redundancias)
@mixin flexbox {
  display: flex;
  width: 100%;

  @content;
}

@mixin flex-center {
  @content;
  align-items: center;
  justify-content: center;
  align-content: center;
}

@mixin set-grid($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  @for $i from 1 through $columns {
    &\:#{$i}#{$breakpoint} {
      width: grid($i);
      flex-shrink: 0;
    }
  }
}

@mixin set-max-grid($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  @for $i from 1 through $columns {
    &\:#{$i}#{$breakpoint} {
      max-width: grid($i);
    }
  }
}

@mixin set-order($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  @for $i from -10 through 10 {
    &\:#{$i}#{$breakpoint} {
      order: $i;
    }
  }
}

@mixin set-flex($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  &\:auto#{$breakpoint} {
    flex: auto;
  }

  &\:0#{$breakpoint} {
    flex: 0;
  }

  @for $i from 1 through 10 {
    &\:#{$i}#{$breakpoint} {
      flex: $i;
    }
  }
}

@mixin set-flex-grow($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  &\:auto#{$breakpoint} {
    flex-grow: auto;
  }

  &\:0#{$breakpoint} {
    flex-grow: 0;
  }

  @for $i from 1 through 10 {
    &\:#{$i}#{$breakpoint} {
      flex-grow: $i;
    }
  }
}

@mixin set-flex-shrink($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  &\:auto#{$breakpoint} {
    flex-shrink: auto;
  }

  &\:0#{$breakpoint} {
    flex-shrink: 0;
  }

  @for $i from 1 through 10 {
    &\:#{$i}#{$breakpoint} {
      flex-shrink: $i;
    }
  }
}

@mixin set-flex-basis($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  &\:auto#{$breakpoint} {
    flex-basis: auto;
  }

  &\:0#{$breakpoint} {
    flex-basis: 0;
  }

  @for $i from 1 through 10 {
    &\:#{$i}#{$breakpoint} {
      flex-basis: $i;
    }
  }
}

@mixin set-directions($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  @each $value in ("row", "column", "row-reverse", "column-reverse") {
    &\:#{$value}#{$breakpoint} {
      flex-direction: #{$value};
    }
  }
}

@mixin set-wrap($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  @each $value in ("nowrap", "wrap", "wrap-reverse") {
    &\:#{$value}#{$breakpoint} {
      flex-wrap: #{$value};
    }
  }
}

@mixin align($prop, $key, $bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  &\:#{$key}#{$breakpoint} {
    #{map.get($flex-alignments, $prop)}: map.get($flex-values, $key);
  }
}

@mixin centered($bp: null) {
  $breakpoint: "";

  @if $bp != null {
    $breakpoint: format-breakpoint($bp);
  }

  &#{$breakpoint} {
    @include flex-center;
  }
}
