@use "sass:map";
@use "sass:math";
@use "sass:string";
@use "../../config" as *;
@use "../mixins" as *;
@use "../functions" as *;

// Lados individuales y combinaciones (top, right, bottom, left, horizontal, vertical)
@mixin space-helper($prefix, $property, $bp: null) {
  @for $i from 1 through 10 {
    $value: math.div($i, 2);
    $label: to-string($value);
    $spacing: to-replace($label, ".", "-");
    $breakpoint: "";

    @if $bp != null {
      $breakpoint: format-breakpoint($bp);
    }

    .#{$prefix}\:#{$spacing}#{$breakpoint} {
      #{$property}: #{$value}rem;
    }

    .#{$prefix}t\:#{$spacing}#{$breakpoint} {
      #{$property}-top: #{$value}rem;
    }

    .#{$prefix}r\:#{$spacing}#{$breakpoint} {
      #{$property}-right: #{$value}rem;
    }

    .#{$prefix}b\:#{$spacing}#{$breakpoint} {
      #{$property}-bottom: #{$value}rem;
    }

    .#{$prefix}l\:#{$spacing}#{$breakpoint} {
      #{$property}-left: #{$value}rem;
    }

    .#{$prefix}v\:#{$spacing}#{$breakpoint} {
      #{$property}-block: #{$value}rem;
    }

    .#{$prefix}h\:#{$spacing}#{$breakpoint} {
      #{$property}-inline: #{$value}rem;
    }
  }
}

// Generar clases para Padding y Margin

@mixin create-paddings-margins {
  @include space-helper("m", "margin");
  @include space-helper("p", "padding");

  @each $bp, $value in $breakpoints {
    @include since($bp) {
      @include space-helper("m", "margin", $bp);
      @include space-helper("p", "padding", $bp);
    }
  }

  // Clases sin margen y sin padding
  .marginless {
    margin: 0;
  }
  .paddingless {
    padding: 0;
  }

  .style {
    &\:square {
      border-radius: 0;
    }

    @each $name, $value in $radius-sizes {
      &\:round-#{$name} {
        border-radius: $value;
      }
    }
  }
}
