@use "../../config" as *;
@use "../../core/mixins" as *;

@mixin create-wrapper {
  .flex {
    &\:wrapper {
      margin: 0 auto;
      width: 100%;
      max-width: 100%;

      @each $bp, $size in $breakpoints {
        @include since($bp) {
          max-width: calc(100% - var(--db-wrap-#{$bp}, 10%));
        }
      }
    }
  }
}
