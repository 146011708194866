@use "sass:math";
@use "sass:map";
@use "../../config" as *;
@use "../../core/mixins" as *;

// Variables globales para colores, tamaños, etc.
$input-colors: (
  default: map.get($colors, smoke),
  hover: map.get($colors, secondary),
  invalid: map.get($colors, danger),
  label: map.get($colors, info),
  background: map.get($colors, white),
);

$input-sizes: (
  height: $elements-height,
  font-size: $font-base,
  label-font-size: 0.8rem,
  label-padding: 0.5rem,
  arrow-right: 1rem,
  arrow-top: 80%,
  label-transform-x: math.div(16px, 2),
  label-transform-y: -8px,
  textarea-min-height: 80px,
);

// Mixin para hover y foco en inputs
@mixin input-hover-focus($color, $bg-color) {
  &:hover,
  &:focus {
    outline: 0;
    background-color: $bg-color;
    border-color: $color;
  }

  &:hover + .ctrl-label,
  &:focus + .ctrl-label {
    transform: translate(
      map.get($input-sizes, label-transform-x),
      map.get($input-sizes, label-transform-y)
    );
    font-size: map.get($input-sizes, label-font-size);
    opacity: 1;
    color: $color;
    background-color: map.get($input-colors, background);
  }
}

// Placeholder común con estilos base para inputs
%input-base {
  appearance: none;
  display: flex;
  width: 100%;
  height: map.get($input-sizes, height);
  align-items: center;
  align-content: center;
  outline: 0;
  padding: 0;
  border-bottom: 1px solid map.get($input-colors, default);
  background-color: transparent;
  transition: all 0.2s ease;
  font-size: map.get($input-sizes, font-size);
  white-space: nowrap;
  word-break: break-all;

  &.hoverable {
    @include input-hover-focus(map.get($input-colors, hover), transparent);
  }

  &.invalid {
    border-color: map.get($input-colors, invalid);
    animation: shaking 0.4s linear;

    & ~ .ctrl-arrow,
    & + .ctrl-label {
      color: map.get($input-colors, invalid);
    }
  }

  &.cleared {
    border: 0;
    background-color: transparent;
  }

  &::-webkit-calendar-picker-indicator {
    opacity: 0;
    display: none;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

@mixin input-helpers {
  // Aplicar los estilos base a diferentes elementos
  .db\:input,
  .db\:select,
  .db\:textarea {
    @extend %input-base;
  }

  // Estilo adicional para `select`
  .db\:select {
    padding-right: 3rem !important;
  }

  // Estilo adicional para `textarea`
  .db\:textarea {
    resize: vertical;
    min-height: map.get($input-sizes, textarea-min-height);
  }

  // Estilos para los elementos de control (label y flecha)
  .db\:ctrl {
    position: relative;
    display: block;
    width: 100%;

    &-label {
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(map.get($input-sizes, font-size), 50%);
      display: inline-flex;
      padding: 0 map.get($input-sizes, label-padding);
      color: map.get($input-colors, label);
      transition: all 0.2s ease;
      pointer-events: none;
      cursor: text;
      border-radius: 1rem;
      opacity: 0;
    }

    &-arrow {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: map.get($input-sizes, arrow-right);
      transform: translateY(map.get($input-sizes, arrow-top));
      transition: all 0.2s ease;
      cursor: pointer;
    }

    &:hover &-arrow {
      color: map.get($input-colors, hover);
    }
  }

  // Animación para estado de error (shaking)
  @keyframes shaking {
    0%,
    100% {
      -webkit-transform: translate(0px, 0px);
    }
    5%,
    40%,
    80% {
      -webkit-transform: translate(-10px, 0px);
    }
    10%,
    50% {
      -webkit-transform: translate(20px, 0px);
    }
  }
}
