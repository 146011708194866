@use "sass:map";
@use "../config" as *;

@mixin reset-styles {
  // Global
  * {
    &,
    &::after,
    &::before {
      margin: 0;
      padding: 0;
      box-sizing: border-box;
    }
  }

  html,
  body {
    width: 100dvw;
    max-width: 100dvw;
    scroll-behavior: smooth;
    font-size: $font-base; // Define font-size base
    font-family: $font-family-base; // Define font-family base
    line-height: $font-line-height;
  }

  body {
    overflow-x: hidden;
    min-height: 100dvh;
    text-rendering: optimizeSpeed;
  }

  // Element-specific resets
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  figure,
  dl,
  dd {
    margin: 0;
  }

  @for $size from 1 through 6 {
    h#{$size} {
      font-family: $font-family-title;
    }
  }

  // Improve list formatting
  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  // Default settings for buttons and inputs
  button,
  input,
  select,
  textarea {
    font: inherit; // Inherit font settings from the parent
    color: inherit;
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    line-height: inherit;
    vertical-align: middle;

    &:hover,
    &:focus,
    &:active {
      outline: none;
    }
  }

  // Ensure images and media scale properly
  img,
  picture,
  video,
  canvas,
  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  // Links
  a {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
  }

  // Tables reset
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
  }

  th,
  td {
    text-align: left;
    padding: 0;
    border: none;
  }

  // Small tags
  small {
    font-size: map.get($font-sizes, small);
  }

  // Preformatted text
  pre {
    white-space: pre-wrap; // Allows wrapping of long lines in preformatted text
    margin: 0;
  }

  // Handle HTML5 semantic elements
  article,
  aside,
  footer,
  header,
  nav,
  section {
    display: block;
  }

  @content;
}
