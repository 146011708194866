@use "sass:map";
@use "sass:string";
@use "../../config" as *;
@use "../mixins" as *;
@use "../functions" as *;

@mixin add-layer($prefix, $property, $bp: null) {
  $min: map.get($layer-range, "min");
  $max: map.get($layer-range, "max");

  @for $i from $min through $max {
    $value: $i;
    $label: to-string($value);
    $spacing: to-replace($label, ".", "-");
    $breakpoint: "";

    @if $bp != null {
      $breakpoint: format-breakpoint($bp);
    }

    .#{$prefix}\:#{$spacing}#{$breakpoint} {
      #{$property}: #{$value};
    }
  }
}

@mixin add-adaptive-layer($prefix, $property, $bp: null) {
  @each $name, $value in $layers {
    $breakpoint: "";

    @if $bp != null {
      $breakpoint: format-breakpoint($bp);
    }

    .#{$prefix}\:#{$name}#{$breakpoint} {
      #{$property}: #{$value};
    }
  }
}

@mixin create-adaptive-layer {
  @each $bp, $value in $breakpoints {
    @include since($bp) {
      @include add-adaptive-layer("layer", "z-index", $bp);
    }
  }
}

@mixin create-layer {
  @include add-layer("layer", "z-index");
  @each $bp, $value in $breakpoints {
    @include since($bp) {
      @include add-layer("layer", "z-index", $bp);
    }
  }
}
