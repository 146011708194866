@use "sass:math";
@use "sass:string";
@use "../../config" as *;
@use "../mixins" as *;
@use "../functions" as *;

// Generar clases para gap
@mixin space-gap($prefix, $property, $size, $bp: null) {
  @for $i from 1 through $size {
    $value: math.div($i, 2);
    $label: to-string($value);
    $spacing: to-replace($label, ".", "-");
    $breakpoint: "";

    @if $bp != null {
      $breakpoint: format-breakpoint($bp);
    }

    .#{$prefix}\:#{$spacing}#{$breakpoint} {
      #{$property}: #{$value}rem;
    }
  }
}

@mixin create-gap {
  @include space-gap("gap", "gap", 10);
  @each $bp, $value in $breakpoints {
    @include since($bp) {
      @include space-gap("gap", "gap", 10, $bp);
    }
  }
}
