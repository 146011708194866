@use "sass:map";
@use "../../config" as *;
@forward "../../core/functions" show get-color;

// Mapa para manejar posiciones y transformaciones de tooltip
$tooltip-positions: (
  right: (
    top: 50%,
    left: 105%,
    transform: translateY(-50%),
  ),
  bottom: (
    top: 115%,
    left: 50%,
    transform: translateX(-50%),
  ),
  top: (
    top: -80%,
    left: 50%,
    transform: translateX(-50%),
  ),
);

// Mixin para los estilos comunes del tooltip
@mixin tooltip-base {
  $text-color: get-color("white");
  $bg-color: get-color("black");

  content: attr(tooltip);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
  border-radius: 5px;
  font-size: 0.8rem;
  color: $text-color;
  background-color: rgb(from $bg-color r g b / 0.8);
  box-shadow: 0 5px 5px -3px rgb(from $bg-color r g b / 0.1);
  position: absolute;
  min-width: max-content;
  opacity: 0;
  transition: all 0.2s ease;
  z-index: 500;
  transform-origin: center center;
}

// Mixin para la posición del tooltip
@mixin tooltip-position($position) {
  top: map.get($position, top);
  left: map.get($position, left);
  transform: map.get($position, transform) scale(0);
}

// Estilos principales del tooltip
@mixin tooltip-helpers {
  [tooltip] {
    position: relative;

    &:after {
      @include tooltip-base;
    }

    @each $name, $position in $tooltip-positions {
      &.tooltip\:#{ $name } {
        &:after {
          @include tooltip-position($position);
        }
        &:hover::after {
          transform: map.get($position, transform) scale(1);
          opacity: 1;
        }
      }
    }
  }
}
