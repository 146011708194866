@use "sass:map";
@use "../../config" as *;
@use "../mixins" as *;
@use "../functions" as *;

%button {
  @include flex-center;
  display: inline-flex;
  flex-wrap: nowrap;
  width: auto;
  height: auto;
  min-height: $elements-height;

  gap: 1rem;

  background-color: transparent;
  border: 1px solid transparent;
  font-size: inherit;
  white-space: nowrap;
  transition: all 200ms;

  cursor: pointer;
  overflow: hidden;
  position: relative;

  * {
    pointer-events: none;
    @include prefix(user-select, none);
    z-index: map.get($layers, root);
  }
}

%disable {
  @include disabled;
}

%icon {
  @extend %button;
  width: $elements-height;
  height: $elements-height;
}
