@use "sass:math";
@use "../../config" as *;
@use "../../core/mixins" as *;

$sizes: (
  "icon": 24,
  "small": 50,
  "medium": 100,
  "large": 150,
);

$-fit-options: (contain, cover);
$-position-options: (center, top, bottom, left, right);

@mixin image-helpers {
  img {
    // Soporte para lazy loading con transición de opacidad
    &[loading="lazy"] {
      opacity: 0;
      transition: opacity 0.3s ease-in-out;

      &.loaded {
        opacity: 1;
      }
    }

    // Soporte para modo oscuro (Dark mode)
    @media (prefers-color-scheme: dark) {
      & {
        filter: brightness(0.9); // Ajustar brillo en modo oscuro
      }
    }
  }

  .fit {
    @each $name in $-fit-options {
      &\:#{$name} {
        object-fit: #{$name};
      }
    }
  }

  .position {
    @each $name in $-position-options {
      &\:#{$name} {
        object-position: #{$name};
      }
    }
  }
}
