@use "sass:map";
@use "sass:color";

// Variables base del sistema - todas con !default para permitir sobrescritura
$columns: 20 !default;
$unity: 16px !default;
$font-base: 16px !default;
$font-line-height: 1.5 !default;
$font-family-base: sans-serif !default;
$font-family-title: sans-serif !default;
$form-elements-height: 45px !default;

// Altura de elementos de formulario (redundancia de _vars.scss eliminada)
$elements-height: $form-elements-height !default;

// Organizar colores en mapas para mejor manejo - unificado de _vars.scss
$colors: (
  primary: #2842eb,
  secondary: #2df20f,
  white: #ffffff,
  black: #181818,
  danger: #c51533,
  warning: #ffae00,
  info: #157bc5,
  success: #00c850,
  smoke: #2842eb,
  light: #ffffff,
  dark: #181818,
) !default;

// Define un mapa de tamaños con valores mínimos, preferidos y máximos
$font-sizes: (
  xs: (
    min: 0.7rem,
    preferred: 0.8rem,
    max: 0.9rem,
  ),
  sm: (
    min: 0.875rem,
    preferred: 2.5dvw,
    max: 1.125rem,
  ),
  md: (
    min: 1rem,
    preferred: 3dvw,
    max: 1.25rem,
  ),
  lg: (
    min: 1.125rem,
    preferred: 3.5dvw,
    max: 1.5rem,
  ),
  xl: (
    min: 1.25rem,
    preferred: 4dvw,
    max: 1.75rem,
  ),
  2xl: (
    min: 1.5rem,
    preferred: 5dvw,
    max: 2rem,
  ),
  w: (
    min: 3rem,
    preferred: 8dvw,
    max: 5dvw,
  ),
) !default;

// Ratios de tamaño de fuente ajustados para un crecimiento progresivo
$font-ratios: (
  xs: 0.75,
  // 12px / 16px
  sm: 0.875,
  // 14px / 16px
  md: 1,
  // 16px
  lg: 1.125,
  // 18px
  xl: 1.475,
  // 22px
  2xl: 2,
  // 28px
  w: 2.5,
) !default;

//radius
$radius-sizes: (
  xs: 4px,
  sm: 8px,
  md: 12px,
  lg: 16px,
  fluid: 5rem,
) !default;

// Mejorar los breakpoints con nombres más descriptivos
$breakpoints: (
  xs: 320px,
  sm: 768px,
  md: 1024px,
  lg: 1366px,
  xl: 1540px,
  2xl: 1920px,
  w: 2200px,
) !default;

// Generate CSS colors variables
$tones: (10%, 20%, 30%, 40%) !default;
$layers: (
  shadow: -1,
  root: 1,
  content: 2,
  modal: 10,
  alert: 20,
  priority: 999,
  important: 9999,
) !default;

// Rango de capas numéricas estándar
$layer-range: (
  min: -3,
  max: 3,
) !default;

// Añadir un sistema de espaciado coherente
$spacing-scale: (
  xs: 4px,
  sm: 8px,
  md: 16px,
  lg: 24px,
  xl: 32px,
  xxl: 48px,
) !default;

// Añadir sistema de sombras
$shadows: (
  xs: 0 2px 4px rgba(0, 0, 0, 0.1),
  sm: 0 4px 8px rgba(0, 0, 0, 0.12),
  md: 0 8px 16px rgba(0, 0, 0, 0.15),
  lg: 0 12px 24px rgba(0, 0, 0, 0.2),
  fluid: 0 16px 32px rgba(0, 0, 0, 0.3),
) !default;
