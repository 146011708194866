@use "sass:map";
@use "../../config" as *;

// Mixin para manejar media queries tanto para 'min-width' como 'max-width'
@mixin media-query($type, $point) {
  $breakpoint-value: map.get($breakpoints, $point);

  @if $breakpoint-value {
    @media screen and (#{$type}-width: $breakpoint-value) {
      @content;
    }
  } @else {
    @media screen and (#{$type}-width: $point) {
      @content;
    }
  }
}

// Mixin 'since' para 'min-width' media queries
@mixin since($point: m) {
  @include media-query(min, $point) {
    @content; // Añadido para pasar el bloque de contenido correctamente
  }
}

// Mixin 'until' para 'max-width' media queries
@mixin until($point: w) {
  @include media-query(max, $point) {
    @content; // Añadido para pasar el bloque de contenido correctamente
  }
}
