@use "../../config" as *;
@use "../mixins" as *;
@use "../functions" as *;

// Añadir soporte para ocultar con otras propiedades como visibility
@mixin hide-element($method: display) {
  @if $method == display {
    display: none;
  } @else if $method == visibility {
    visibility: hidden;
  }
}

@mixin create-hiddens {
  // Uso del mixin para múltiples propiedades de ocultación
  @each $class, $value in $breakpoints {
    .hide\:#{$class} {
      @include since($class) {
        @include hide-element(display);
      }
    }

    .hide-from\:#{$class} {
      @include until($class) {
        @include hide-element(display);
      }
    }

    .invisible\:#{$class} {
      @include since($class) {
        @include hide-element(visibility);
      }
    }

    .invisible-from\:#{$class} {
      @include until($class) {
        @include hide-element(visibility);
      }
    }
  }
}
