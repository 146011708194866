@use "sass:math";
@use "sass:string";
@use "../../config" as *;
@use "../mixins" as *;
@use "../functions" as *;

@mixin add-opacity($prefix, $property, $size, $bp: null) {
  @for $i from 0 through $size {
    $value: math.div($i, 10);
    $label: to-string($value);
    $spacing: to-replace($label, ".", "-");
    $breakpoint: "";

    @if $bp != null {
      $breakpoint: format-breakpoint($bp);
    }

    .#{$prefix}\:#{$spacing}#{$breakpoint} {
      #{$property}: #{$value};
    }
  }
}

@mixin create-opacity {
  @include add-opacity("opacity", "opacity", 10);
  @each $bp, $value in $breakpoints {
    @include since($bp) {
      @include add-opacity("opacity", "opacity", 10, $bp);
    }
  }
}