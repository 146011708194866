@use "sass:map";
@use "sass:string";
@use "../../config" as *;
@use "../functions" as *;

@mixin prefix($property, $value) {
  -webkit-#{$property}: $value; // Prefijo para Webkit (Chrome, Safari)
  -moz-#{$property}: $value; // Prefijo para Mozilla Firefox
  -ms-#{$property}: $value; // Prefijo para Internet Explorer
  #{$property}: $value; // Propiedad estándar
  @content;
}

@mixin hover {
  &:not([disabled]):hover {
    @content;
  }
}

@mixin disabled {
  &[disabled] {
    pointer-events: none !important;
    @include prefix(user-select, none);
    cursor: not-allowed;
    @content;
  }
}

@mixin create-theme($key) {
  $name: to-string($key);
  &.theme\:#{$name} {
    @content;
  }
}

@mixin theme-colors($background_color: $black, $text_color: $white) {
  background-color: $background_color;
  color: $text_color;
  @content;
}

@mixin icon-size($size: 24px) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
  object-fit: contain;
  object-position: center;

  @content;
}

// Mixin para soporte Retina
@mixin retina($image, $width, $height, $ratio) {
  background-image: url("#{$image}");
  width: $width;
  height: $height;
  aspect-ratio: $ratio;

  @media only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (min--moz-device-pixel-ratio: 2),
    only screen and (-o-min-device-pixel-ratio: 2/1),
    only screen and (min-device-pixel-ratio: 2),
    only screen and (min-resolution: 192dpi),
    only screen and (min-resolution: 2dppx) {
    background-image: url("#{$image}@2x");
    @content;
  }
}
