@use "sass:map";
@use "sass:color";
@use "../../config" as *;
@use "../../core/placeholders" as *;
@use "../../core/mixins" as *;
@use "../../core/functions" as *;

@mixin button-helpers {
  .ui {
    &\:button {
      @extend %button;
      padding-inline: to-rem(16px);
    }

    &\:icon {
      @extend %icon;
      flex-shrink: 0;

      * {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &\:button,
    &\:icon {
      @extend %disable;

      @each $name, $color in $colors {
        $text_color: get-text-color($name);

        @include create-theme($name) {
          @include theme-colors($color, $text_color) {
            @include hover {
              @include theme-colors(
                color.adjust($color, $lightness: -20%),
                $text_color
              );
            }
          }
        }
      }
    }
  }
}
